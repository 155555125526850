.quote-btn {
  background-color: #00cc00;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  outline: none;
  font-size: large;
  margin-top: 2rem;
}

.quote-btn:hover {
  background-color: #00ff00;
}