@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(4rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.photo-container{
  position: fixed;
  top: 0;
  padding-top: 7rem;
  padding-bottom: 7rem;
  width: 100%;
  height: 100%;
  background-image: url('/public/laptop32.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
  filter: blur(0px) brightness(40%);
}

.frontpage-container {
  margin: 0 auto;
  padding: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.frontpage-container h1 {
  font-size: 5rem;
  text-align: center;
  margin-top: 6rem;
 /* opacity: 0; /* Initially set the opacity to 0 to hide the reason boxes */
 /* transition: opacity 1.0s ease-in-out, transform 0.8s ease-out; /* Apply transitions */
  animation: fadeInUp 0.7s ease-out; /* Apply animation */
}

.frontpage-container h2 {
  animation: fadeInDown 1.0s ease-out; /* Apply animation */
}

/*.frontpage-container h1.reason-visible {
  opacity: 1; /* When the reason box becomes visible, set opacity to 1 */
 /* transform: translateY(0); /* Reset the transform to 0, making the box appear in its original position */
/*} */

.frontpage-container .intro-container {
  width: 85%;
  max-width: 1200px;
  min-height: 400px;
  margin-top: 10rem;
  margin-bottom: 4rem;
}

.frontpage-container .intro-container h1 {
  font-size: 6vh;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.frontpage-container .intro-container h2 {
  font-size: 4vh;
  text-align: center;
  margin-bottom: 2rem;
}

.frontpage-container .services-container {
  width: 85%;
  max-width: 1200px;
  text-align: justify;
  margin-top: 3rem;
}

.frontpage-container .services-container h3 {
  font-size: 22px;
  margin: 10px 0;
}

.frontpage-container .services-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.frontpage-container .intro-container .intro-p {
  font-size: 1.5rem;
  margin-bottom: 8vh;
}

.frontpage-container .collab-container .collab-h {
  margin-top: 2rem;
  padding-top: 2rem;
}

.frontpage-container .services-container .reasons-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.frontpage-container .services-container .reason {
  padding: 20px;
  border: 1px solid #ccc;
  opacity: 0;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.85);
}

.frontpage-container .services-container .reason.reason-visible {
  animation: fadeInUp 1.1s ease-out; 
  opacity: 1;
}

.frontpage-container .services-container .reason.reason-visible .span-reason {
  color: #00ff00;
}

.frontpage-container .collab-container {
  background-color: rgba(0, 0, 0, 0.85);
  max-width: 75%;
  margin-top: 5rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.frontpage-container .collab-container h3 {
  text-align: start;
  font-size: 22px;
  margin-left: 5rem;
}

.frontpage-container .collab-container ul {
  list-style-type: disc;
  text-align: start;
  margin-left: 5rem;
  margin-bottom: 20px;
  max-width: 800px;
  width: 90%;
  font-size: large;
  gap: 1rem;
}

.frontpage-container .collab-container ul li {
  font-size: large;
  padding-bottom: 0.5rem;
}

/*.frontpage-container .services-container .reason.reason-visible {
  opacity: 0; /* When the reason box becomes visible, set opacity to 1 */
 /* transform: translateY(0); /* Reset the transform to 0, making the box appear in its original position */
/*} */

.frontpage-container .services-container .reason p {
  margin: 10px 0;
}

.frontpage-container .services-container h3 {
  font-size: 22px;
  margin: 10px 0;
}

.frontpage-container .services-container ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 20px;
}

.frontpage-container .services-container li {
  font-size: 16px;
  margin-bottom: 5px;
}

.frontpage-container .services-container .contact-span {
  font-weight: bold;
}

.frontpage-container .collab-container .hover-path {
  fill: #3498db; /* Initial fill color */
  transition: fill 0.3s;
}

.frontpage-container .collab-container .hover-path:hover {
  d: path("M10 90 L90 90 L50 10 Z"); /* New path data when hovered */
  fill: #e74c3c; /* New fill color when hovered */
}

svg {
  transition: transform 0.3s;
  cursor: pointer;
}

svg:hover path:nth-child(1) {
  transform: translateX(100px) rotateY(-45deg);
}

.black-div {
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
  position: fixed;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9907;
}

.black-div-photo {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url('/public/WS1006.png');
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: center;
  position: absolute;
  filter: blur(0px) brightness(30%);
  animation: pulse 2s infinite; 
  z-index: 9908;
}

@media (max-width: 768px) {

  .photo-container {
    background-image: url('/public/laptop32.png');
    background-size: 150%;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 0;
    background-attachment: scroll;
    height: 100vh;
  }

  .frontpage-container .intro-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .frontpage-container .intro-container h1 {
    font-size: 5vh;
  }

  .intro-p {
    display: none;
  }

  .frontpage-container .services-container .reason {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .frontpage-container .collab-container {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .frontpage-container .collab-container ul {
    list-style-type: disc;
    text-align: start;
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 800px;
    width: 90%;
    font-size: large;
    gap: 1rem;
  }
}
  


