@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.quote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 9rem;
  padding-top: 10rem;
  animation: fadeInLeft 1.1s ease-in-out;
}

.quote-container h1 {
  font-size: 5vh;
  margin-bottom: 3rem;
}

.quote-container .quote-p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.quote-container .quote-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topic-div {
  margin-bottom: 1rem;
}

.quote-container .send-btn {
  background-color: #00cc00;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-size: large;
  width: fit-content;
  align-self: center;
}

.quote-container .send-btn:hover {
  background-color: #00ff00;
}

.empty-p {
  padding: 1.4vh;
}

@media (max-width: 768px) {
  .quote-container {
    margin: 0;
    padding: 0;
    padding-top: 6rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .quote-form {
    width: fit-content;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }

  .quote-p {
    padding: 1rem 1rem;
  }
}

