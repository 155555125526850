@keyframes fadeInView {
  from {
    opacity: 0;
    transform: translateX(15rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  z-index: 9999;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 50;
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 9999;
  padding: 0 20px;
  background-color: #000000;
  transition: transform 0.7s ease;
}

.visible {
  transform: translateY(100); /* Show the header */
}

.hidden {
  transform: translateY(-100%); /* Hide the header */
}
/*
.contact-button {
  overflow: hidden;
  transform: translateX(200%);
  transition: transform 0.8s ease;
  top: 1.5rem;
  z-index: 100;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.contact-button.show {
  transform: translateX(0);
  position: fixed;
} */

.contact-button button {
  background-color: #00cc00;
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  outline: none;
  font-size: medium;
  position: fixed;
  z-index: 9999;
  top: 2rem;
  right: 2rem;
  animation: fadeInView 1.1s ease-out;
}

.contact-button button:hover {
  background-color: #00ff00;
}

.aces-h {
  color: #00ff00;
}

.softa-span {
  color: #ffffff;
}

.com-span {
  color: #ffffff;
  font-size: medium;
}

@media (max-width: 768px) {

  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .contact-button button {
    top: 2rem;
    right: 1rem;
  }
}