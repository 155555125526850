@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-15rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.mob-header-container {
  position: fixed;
  top: 0;
  width: 100vw;
  align-items: center;
  z-index: 9999;
  height: 5rem;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.logo-link-div {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.header-logo {
  width: fit-content;
  margin: 0.5rem;
  cursor: pointer;
}

.header-logo h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0.5rem;
  padding-top: 0.5rem;
}

.aces-h {
  color: #00ff00;
}

.softa-span {
  color: #ffffff;
}

.com-span {
  color: #ffffff;
  font-size: medium;
}

.menu-btn {
  position: fixed;
  font-size: 2rem;
  top: 2rem;
  left: 0.5rem;
  width: 3rem;
  height: 3rem;
  color: #ffffff;
  background-color: #000000;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 100;
}

.menu {
  position: fixed;
  top: 5rem;
  left: -350px; /* Offscreen initially */
  width: 300px;
  height: 100%;
  background-color: #000000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 1);
  z-index: 999;
  color: #ffffff;
  animation: fadeInRight 0.7s ease-out;
}

.menu.open {
  left: 0;
  transition: left 0.9s ease-in-out; 
}

.menu-list {
  color: #ffffff;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu-list li {
  padding: 0.2rem;
  font-size: 1.5rem;
  color: #ffffff;
}

.menu-list a {
  padding: 0.5rem;
  color: #ffffff;
  text-decoration: none;
} 