@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.contact-container {
  padding-top: 10rem;
  padding-left: 10rem;
  padding-right: 10rem;
  background: #000000;
  animation: fadeInLeft 1.1s ease-in-out;
}

.contact-container h2 {
  font-size: 5vh;
  margin-bottom: 2rem;
}

.message-p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.contact-form .p-sent {
  align-self: self-start;
}

.form-div {
  align-self: self-start;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 0.5rem;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"] {
  width: fit-content;
  min-width: 40%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

}

input[type="email"] {
  width: fit-content;
  min-width: 40%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

input::placeholder {
  font-family: "Montserrat", sans-serif;
}

textarea {
  width: fit-content;
  min-width: 70%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 120px;
}

textarea::placeholder {
  font-family: "Montserrat", sans-serif;
}

.contact-container .btn-send {
  background-color: #00cc00;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-size: large;
  width: fit-content;
}

.btn-send:hover {
  background-color: #00ff00;
}

h2 {
  margin-bottom: 20px;
  font-size: 40px;
  padding-bottom: 2rem;
}

form div {
  display: flex;
  flex-direction: column;
}

form .form-div {
  display: flex;
  flex-direction: column;
  justify-items: left;
}

.empty-p {
  padding: 1.5vh;
}

.contacts {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

hr {
  margin: 2rem 2rem;
}

.contact-info-p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.info {
  display: flex;
  flex-direction: column;
  color: #cfcfcf;
  align-items: center;
  align-self: self-center;
  justify-items: flex-center;
  justify-content: flex-start;
  padding: 1rem 1rem;
}

.info-entre {
  font-size: large;
  font-weight: bold;
}

.info-div {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  border: #ffffff solid 1px;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
}

@media (max-width: 768px) {
  .contact-container {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 6rem;
    position: relative;
  }

  .contacts {
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .info {
    align-items: center;
    justify-content: center;
  }

  .form-div {
    align-items: center;
    justify-content: center;
  }

  .form-div input[type="text"] {
    width: 100%;
  }

  .form-div input[type="email"] {
    width: 100%;
  }

  .form-div textarea {
    width: 100%;
  }

  .contact-container .btn-send {
    width: fit-content;
  }

  hr {
    min-width: 90%;
    margin-top: 4rem;
  }
}

